<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :loader="$store.state.spinerLoader"
      :color="$store.state.spinerColor"
    ></loading>
    <vue-snotify></vue-snotify>
    <CCard class="card-border">
      <div
        id="rest-bg-imge"
        class="rest-bg-imge"
        v-bind:style="{ backgroundImage: 'url(' + menuBackgroundImage + ')' }"
      >
        <CCarousel
          :interval="carousel_interval"
          arrows
          indicators
          animate
          height="300px"
          v-if="menuSliders && menuSliders.length"
        >
          <CCarouselItem
            v-for="(item, index) in menuSliders"
            :key="item.url"
            class="image-setting"
            :image="item.url"
          />
        </CCarousel>

        <div class="resturent-main-card">
          <CRow class="pb-3">
            <CCol md="12">
              <CRow class="pt-2 pl-3">
                <CCol md="8" sm="12">
                  <div class="pt-2">
                    <h5 id="traffic" class="card-title mb-0">
                      <b>{{ restaurant_name }}</b>
                    </h5>
                    <div class="midium location-text">
                      <b>{{
                        restaurantData.city ? restaurantData.city.name : ""
                      }}</b>
                      <!-- <CBadge
                        color="primary"
                        class="ml-1"
                        @click="mapRedirect(restaurantData)"
                        >Show in Map</CBadge
                      > -->
                    </div>
                    <div class="small address-text">
                      <b>{{ restaurantData.restaurant_address }}</b>
                    </div>
                  </div>
                </CCol>
                <CCol md="4" sm="12">
                  <div class="rating-arrangment pt-2 pr-2">
                    <CBadge
                      v-if="restaurantData.rating"
                      class="badge-color rating-ipad"
                      color="primary"
                      >{{
                        restaurantData.rating
                          ? restaurantData.rating.toFixed(1)
                          : ""
                      }}
                      <i class="fas fa-star"></i
                    ></CBadge>
                  </div>
                </CCol>
                <CCol md="12">
                  <div class="small description-text pr-2">
                    <b>
                      <div
                        v-if="restaurantData.description"
                        class="custom-readmore"
                        v-readMore:300="restaurantData.description"
                      ></div>
                    </b>
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
<!--            <CCol class="col-md4">-->
<!--              -->
<!--            </CCol>-->
<!--            <CCol class="col-md4">-->

<!--            </CCol>-->



            <CCol sm="4"> </CCol>
            <CCol sm="8">
              <div class="float-right p-2">
                <CButton
                  color="primary"
                  @click="
                    $router.push({
                      name: 'RestaurantMenuView',
                      params: { tenant_id: tenant_id },
                    })
                  "
                  >Menu</CButton
                >
<!--                <CButton-->
<!--                    class="ml-2"-->
<!--                    color="primary"-->
<!--                    @click="-->
<!--                    $router.push({-->
<!--                      name: 'RestaurantMenuViewNew',-->
<!--                      params: { tenant_id: tenant_id },-->
<!--                    })-->
<!--                  "-->
<!--                >New Menu</CButton-->
<!--                >-->
                <!-- <CButton color="light" class="ml-2" variant="outline" @click="goBack()">Back</CButton> -->
              </div>
            </CCol>

          </CRow>
          <!-- Start reservation form -->
          <CRow id="reservation_div" class="justify-content-center mt-4 p-1">
            <CCol md="8">
              <CCard class="m-2 resturent-reserv-card">
                <div class="p-4">
                  <h4 class="title-text">Make a Reservation</h4>
                  <hr color="white" />

                  <form-wizard
                    :start-index="0"
                    ref="wizard"
                    title=""
                    subtitle=""
                    color="#7F30BF"
                    v-if="!customer_access_token"
                    class="custom-wizard"
                  >
                    <tab-content
                      title="Personal details"
                      :before-change="genereateOtp"
                    >
                      <ValidationObserver ref="step1_form">
                        <CRow v-show="!opt_show">
                          <CCol md="4">
                            <label>Do you have ScanMe account?</label>
                          </CCol>
                          <CCol md="8">
                            <ValidationProvider
                              vid="account_exists"
                              name="ScanMe account exist"
                              v-slot="{ errors }"
                              rules=""
                            >
                              <!-- <CInputRadioGroup
                                :options="[
                                  {
                                    value: 'Yes',
                                    label: 'Yes ',
                                  },
                                  {
                                    value: 'No',
                                    label: 'No ',
                                  },
                                ]"
                                @update:checked="accountExists"
                                inline
                                size="lg"
                              /> -->

                              <CSwitch
                                labelOn="Yes"
                                labelOff="No"
                                @update:checked="accountExists"
                                :checked.sync="account_exists"
                                color="primary"
                                class="mb-2"
                              />

                              <input type="hidden" v-model="account_exists" />
                              <div
                                v-if="errors[0]"
                                class="wizard-error mt-2 mb-3"
                              >
                                <span
                                  v-for="(error, index) in errors"
                                  v-bind:key="index"
                                >
                                  {{ error }}
                                </span>
                              </div>
                            </ValidationProvider>
                          </CCol>
                        </CRow>
                        <div v-show="!opt_show">
                          <ValidationProvider
                            vid="mobile_no"
                            name="mobile no"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <CRow>
                              <CCol md="4">
                                <label class="sign-up-lable"
                                  >Enter your mobile no *</label
                                >
                              </CCol>
                              <CCol md="8">
                                <CInput
                                  v-model="form_params.mobile_no"
                                  v-mask="'+94#########'"
                                  placeholder="(+94)-XXXXXXXXX"
                                  :is-valid="checkValidInput(errors)"
                                />

                                <div v-if="errors[0]" class="wizard-error">
                                  <span
                                    v-for="(error, index) in errors"
                                    v-bind:key="index"
                                  >
                                    {{ error }}
                                  </span>
                                </div>
                              </CCol>
                            </CRow>
                          </ValidationProvider>
                        </div>

                        <CRow
                          v-show="
                            !opt_show && this.customerAccountExist == 'No'
                          "
                        >
                          <CCol md="4">
                            <label>Name *</label>
                          </CCol>
                          <CCol md="4">
                            <ValidationProvider
                              vid="first_name"
                              name="First Name"
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <CInput
                                v-model="first_name"
                                placeholder="First Name"
                                size="md"
                              >
                              </CInput>
                              <div v-if="errors[0]" class="wizard-error">
                                <span
                                  v-for="(error, index) in errors"
                                  v-bind:key="index"
                                >
                                  {{ error }}
                                </span>
                              </div>
                            </ValidationProvider>
                          </CCol>
                          <CCol md="4">
                            <ValidationProvider
                              vid="last_name"
                              name="Last Name"
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <CInput
                                v-model="last_name"
                                placeholder="Last Name"
                                size="md"
                              >
                              </CInput>
                              <div v-if="errors[0]" class="wizard-error">
                                <span
                                  v-for="(error, index) in errors"
                                  v-bind:key="index"
                                >
                                  {{ error }}
                                </span>
                              </div>
                            </ValidationProvider>
                          </CCol>
                        </CRow>

                        <CRow v-show="!opt_show" class="justify-content-center">
                          <CCol md="4"> <label>Date *</label> </CCol>
                          <CCol md="8">
                            <ValidationProvider
                              vid="reserved_date"
                              name="date"
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <!-- <CInput v-model="reserved_date" type="date" /> -->

                              <datepicker
                                class="pb-3"
                                open="true"
                                :bootstrap-styling="true"
                                :disabledDates="disabledReservationDates"
                                placeholder="DD / MM / YYYY"
                                v-model="reserved_date"
                                format="dd / MM / yyyy"
                                input-class="form-control"
                                ref="dp1"
                              >
                              </datepicker>

                              <div v-if="errors[0]" class="wizard-error">
                                <span
                                  v-for="(error, index) in errors"
                                  v-bind:key="index"
                                >
                                  {{ error }}
                                </span>
                              </div>
                            </ValidationProvider>
                          </CCol>
                        </CRow>

                        <CRow v-show="!opt_show">
                          <CCol md="4">
                            <label>Time</label>
                          </CCol>
                          <CCol md="8">
                            <ValidationProvider
                              vid="reserved_time"
                              name="Time"
                              v-slot="{ errors }"
                              rules=""
                            >
                              <div class="form-group">
                                <div>
                                  <vue-timepicker
                                    label="Time"
                                    format="HH:mm"
                                    v-model="reserved_time"
                                  ></vue-timepicker>
                                </div>
                              </div>

                              <div v-if="errors[0]" class="wizard-error">
                                <span
                                  v-for="(error, index) in errors"
                                  v-bind:key="index"
                                >
                                  {{ error }}
                                </span>
                              </div>
                            </ValidationProvider>
                          </CCol>
                        </CRow>

                        <CRow v-show="!opt_show">
                          <CCol md="4">
                            <label>Number of Persons</label>
                          </CCol>
                          <CCol md="8">
                            <ValidationProvider
                              vid="no_of_people"
                              name="Number of Persons"
                              v-slot="{ errors }"
                              rules=""
                            >
                              <CInput
                                v-model="no_of_people"
                                placeholder="Number of Persons"
                                size="md"
                              >
                              </CInput>
                              <div v-if="errors[0]" class="wizard-error">
                                <span
                                  v-for="(error, index) in errors"
                                  v-bind:key="index"
                                >
                                  {{ error }}
                                </span>
                              </div>
                            </ValidationProvider>
                          </CCol>
                        </CRow>

                        <CRow v-show="!opt_show">
                          <CCol md="4">
                            <label>Select Reservation Area *</label>
                          </CCol>
                          <CCol md="8">
                            <ValidationProvider
                              vid="area_id"
                              name="dining area"
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <CSelect
                                :options="areaListArray"
                                placeholder="Select Reservation Area"
                                :value.sync="area_id"
                              />
                              <div v-if="errors[0]" class="wizard-error">
                                <span
                                  v-for="(error, index) in errors"
                                  v-bind:key="index"
                                >
                                  {{ error }}
                                </span>
                              </div>
                            </ValidationProvider>
                          </CCol>
                        </CRow>

                        <CRow v-show="!opt_show">
                          <CCol md="4">
                            <label>Special Notes</label>
                          </CCol>
                          <CCol md="8">
                            <ValidationProvider
                              vid="remarks"
                              name="Special Notes"
                              v-slot="{ errors }"
                              rules=""
                            >
                              <CTextarea
                                v-model="remarks"
                                size="md"
                                placeholder="Special Notes"
                              ></CTextarea>
                              <div v-if="errors[0]" class="wizard-error">
                                <span
                                  v-for="(error, index) in errors"
                                  v-bind:key="index"
                                >
                                  {{ error }}
                                </span>
                              </div>
                            </ValidationProvider>
                          </CCol>
                        </CRow>

                        <div v-show="opt_show">
                          <ValidationProvider
                            vid="otp"
                            name="otp"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <CRow>
                              <CCol md="4">
                                <label
                                  >Enter the code sent to
                                  {{ form_params.mobile_no }}</label
                                >
                              </CCol>
                              <CCol md="4">
                                <input
                                  type="hidden"
                                  v-model="form_params.otp"
                                />
                                <!-- <v-otp-input
                                  inputClasses="otp-input"
                                  :numInputs="4"
                                  separator="-"
                                  :shouldAutoFocus="true"
                                  @on-complete="handleOnComplete"
                                  @on-change="handleOnChange"
                                /> -->
                                <!-- <br /> -->
                                <input
                                  class="text-center otp-txt-box"
                                  type="text"
                                  v-mask="'#-#-#-#'"
                                  v-model="otp_mask"
                                  placeholder="-  -  -  -"
                                />

                                <div v-if="errors[0]" class="wizard-error">
                                  <span
                                    v-for="(error, index) in errors"
                                    v-bind:key="index"
                                  >
                                    {{ error }}
                                  </span>
                                </div>
                              </CCol>
                              <CCol md="4">
                                <div
                                  class="text-white text-center"
                                  v-show="opt_show"
                                >
                                  Time Remaining - {{ prettyTime }}
                                </div>
                                <div class="text-center">
                                  <a
                                    v-show="opt_show"
                                    class="location-color"
                                    href="#"
                                    >Resend otp</a
                                  >
                                </div>
                              </CCol>
                            </CRow>
                          </ValidationProvider>
                        </div>
                      </ValidationObserver>
                    </tab-content>
                    <template slot="footer" scope="props">
                      <CRow>
                        <CCol md="4"> </CCol>
                        <CCol md="8">
                          <div class="wizard-footer-left">
                            <!-- remove previous button -->
                            <!-- <wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button> -->
                          </div>
                          <div
                            class="wizard-footer-right"
                            v-show="!isTimerRunning"
                          >
                            <wizard-button
                              @click.native="props.nextTab()"
                              class="wizard-footer-right finish-button"
                              :style="props.fillButtonStyle"
                              >Submit</wizard-button
                            >
                          </div>
                        </CCol>
                      </CRow>
                    </template>
                  </form-wizard>

                  <ValidationObserver
                    ref="reservation_form"
                    v-if="customer_access_token"
                  >
                    <CRow class="justify-content-center">
                      <CCol md="4"> <label>Date *</label> </CCol>
                      <CCol md="8">
                        <ValidationProvider
                          vid="reserved_date"
                          name="date"
                          v-slot="{ errors }"
                          rules=""
                        >
                          <datepicker
                            class="pb-3"
                            open="true"
                            :bootstrap-styling="true"
                            :disabledDates="disabledReservationDates"
                            placeholder="DD / MM / YYYY"
                            v-model="reserved_date"
                            format="dd / MM / yyyy"
                            input-class="form-control"
                            ref="dp1"
                          >
                          </datepicker>
                          <div v-if="errors[0]" class="wizard-error">
                            <span
                              v-for="(error, index) in errors"
                              v-bind:key="index"
                            >
                              {{ error }}
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol md="4">
                        <label>Time</label>
                      </CCol>
                      <CCol md="8">
                        <ValidationProvider
                          vid="reserved_time"
                          name="Time"
                          v-slot="{ errors }"
                          rules=""
                        >
                          <div class="form-group">
                            <div>
                              <vue-timepicker
                                label="Time"
                                format="HH:mm"
                                v-model="reserved_time"
                              ></vue-timepicker>
                            </div>
                          </div>

                          <div v-if="errors[0]" class="wizard-error">
                            <span
                              v-for="(error, index) in errors"
                              v-bind:key="index"
                            >
                              {{ error }}
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol md="4">
                        <label>Number of Persons</label>
                      </CCol>
                      <CCol md="8">
                        <ValidationProvider
                          vid="no_of_people"
                          name="Number of Persons"
                          v-slot="{ errors }"
                          rules=""
                        >
                          <CInput
                            v-model="no_of_people"
                            placeholder="Number of Persons"
                          >
                          </CInput>
                          <div v-if="errors[0]" class="wizard-error">
                            <span
                              v-for="(error, index) in errors"
                              v-bind:key="index"
                            >
                              {{ error }}
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol md="4">
                        <label>Select Reservation Area *</label>
                      </CCol>
                      <CCol md="8">
                        <ValidationProvider
                          vid="area_id"
                          name="dining area"
                          v-slot="{ errors }"
                          rules=""
                        >
                          <CSelect
                            :options="areaListArray"
                            placeholder="Select Reservation Area"
                            :value.sync="area_id"
                          />
                          <div v-if="errors[0]" class="wizard-error">
                            <span
                              v-for="(error, index) in errors"
                              v-bind:key="index"
                            >
                              {{ error }}
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol md="4">
                        <label>Special Notes</label>
                      </CCol>
                      <CCol md="8">
                        <ValidationProvider
                          vid="remarks"
                          name="Special Notes"
                          v-slot="{ errors }"
                          rules=""
                        >
                          <CTextarea
                            v-model="remarks"
                            placeholder="Special Notes"
                          ></CTextarea>
                          <div v-if="errors[0]" class="wizard-error">
                            <span
                              v-for="(error, index) in errors"
                              v-bind:key="index"
                            >
                              {{ error }}
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol md="4"> </CCol>
                      <CCol md="8">
                        <div class="float-right pb-3 w-100">
                          <CButton
                            color="primary"
                            class="w-100 wizard-btn-smaple"
                            @click="reservationCreate"
                            ><b>Submit</b></CButton
                          >
                        </div>
                      </CCol>
                    </CRow>
                  </ValidationObserver>
                </div>
              </CCard>
            </CCol>
          </CRow>

          <!-- End reservation form -->

          <!-- Start restaurent areia slider -->
          <CRow id="area_div" class="justify-content-center mt-4 p-1">
            <CCol md="8">
              <CCard class="m-2 resturent-reserv-card">
                <div class="pl-3 pr-3 pt-2">
                  <h4 class="title-text">Reservation Areas</h4>
                </div>
              </CCard>

              <CCard
                class="m-2 resturent-reserv-card"
                v-if="areaData"
                v-for="(item, index) in areaData"
                :key="item.url"
              >
                <div>
                  <div class="pl-3 pr-3 pt-3">
                    <h6 class="title-text">{{ item.name }}</h6>
                    <hr color="white" />
                  </div>

                  <CCarousel
                    class="image-setting"
                    :image="item.url"
                    arrows
                    indicators
                    animate
                    height="350px"
                  >
                    <span>
                      <CCarouselItem
                        image="https://picsum.photos/1024/480/?image=52"
                        v-for="(itemImg, indexImg) in item.image_collection"
                        :key="itemImg.url"
                        class="image-setting"
                        :image="itemImg.url"
                      />
                    </span>
                  </CCarousel>
                  <!-- <h5 class="title-text pl-3">{{item.description}}</h5> -->
                  <div class="pl-5 pr-5 pb-3 text-white">
                    <div
                      v-if="item.description"
                      class="custom-readmore"
                      v-readMore:150="item.description"
                    ></div>
                  </div>
                </div>
              </CCard>
            </CCol>
          </CRow>
          <!-- End restaurent areia slider -->

          <!-- Start restaurent Social Media -->
          <CRow class="justify-content-center mt-4 p-1">
            <CCol md="8">
              <CCard class="m-2 resturent-reserv-card pb-4">
                <div class="p-3">
                  <h4 class="title-text">Social Media</h4>
                  <hr color="white" />
                </div>
                <div class="Row">
                  <div class="Column">
                    <CIcon
                      name="cib-facebook"
                      height="40"
                      class="social-color"
                      @click.native="
                        redirectToLink(restaurantData.facebook_link)
                      "
                    />
                  </div>
                  <div class="Column">
                    <CIcon
                      name="cib-youtube"
                      height="40"
                      class="social-color"
                      @click.native="
                        redirectToLink(restaurantData.facebook_link)
                      "
                    />
                  </div>
                  <div class="Column">
                    <CIcon
                      name="cib-instagram"
                      height="40"
                      class="social-color"
                      @click.native="
                        redirectToLink(restaurantData.facebook_link)
                      "
                    />
                  </div>
                </div>
              </CCard>
            </CCol>
          </CRow>
          <!-- End restaurent Social Media -->

          <!-- Start restaurent Location -->
          <CRow class="justify-content-center mt-4 p-1">
            <CCol md="8">
              <CCard class="m-2 resturent-reserv-card pb-4">
                <div class="p-3">
                  <h4 class="title-text">Location</h4>
                  <hr color="white" />
                </div>
                <CRow>
                  <CCol md="12">
                    <RestaurantMapView
                      v-if="restaurantData.location"
                      :lat="
                        restaurantData.location
                          ? JSON.parse(restaurantData.location).lat
                          : '6.9271'
                      "
                      :lng="
                        restaurantData.location
                          ? JSON.parse(restaurantData.location).lng
                          : '79.8612'
                      "
                    />
                  </CCol>
                </CRow>
              </CCard>
            </CCol>
          </CRow>
          <!-- End restaurent Location -->
          <CRow>
            <CCol md="12">
              <div class="min-height-scroll-fixer"></div>
            </CCol>
          </CRow>
        </div>
      </div>
    </CCard>
  </div>
</template>

<script>
import RestaurantMenuCard from "@/component/RestaurantMenuCard/RestaurantMenuCard.vue";
import RestaurantMapView from "@/views/pages/RestaurantMapView";
import Register from "@/views/pages/Register";
import { mapActions, mapState, mapGetters } from "vuex";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import store from "../../store/store";
import commonMixin from "@/mixins/common";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
let currentDate = new Date();
currentDate.setDate(currentDate.getDate() - 1);
export default {
  name: "RestaurantBookingForm",
  components: {
    RestaurantMenuCard,
    VueTimepicker,
    RestaurantMapView,
    Register,
    Datepicker,
  },
  watch: {
    otp_mask: {
      handler: function (after, before) {
        let otpStr = this.otp_mask;
        if (otpStr.length == 7) {
          otpStr = otpStr.replace(/-/g, "");
          this.handleOnComplete(otpStr);
        }
      },
      deep: true,
    },
  },
  mixins: [commonMixin],
  methods: {
    ...mapActions("restaurant", [
      "getRestaurantData",
      "getMenuCategoryArray",
      "filterMenuItems",
      "reservationBooking",
    ]),
    ...mapActions("user", [
      "genereateCustomerOtpForReservation",
      "checkOtpValidity",
      "checkCustomerInfoValidity",
      "checkCustomerMobileForReservation",
      "generateCustomerToken",
      "generateCustomerTokenAfterReservation",
      "genereateCustomerCommonOtp",
    ]),
    ...mapActions("settings", ["areaList"]),
    onfocusin() {
      setTimeout((ev) => {
        this.$refs.dp1.isOpen || this.$refs.dp1.showCalendar(ev);
      }, 50);
    },
    genereateOtp() {
      if (this.customerAccountExist == "Yes") {
        this.genereateLoginOtp();
      } else {
        this.genereateRegisterOtp();
      }
      //this.genereateCommonOtp();
    },
    genereateCommonOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        reserved_date: this.reserved_date
          ? moment(this.reserved_date).format("YYYY-MM-DD")
          : "",
        reserved_time: _.isEqual(this.reserved_time, this.initial_time)
          ? ""
          : this.formatTime(this.reserved_time),
        tenant_id: this.tenant_id,
        no_of_people: this.no_of_people,
        area_id: this.area_id,
        remarks: this.remarks,
        first_name: this.first_name,
        last_name: this.last_name,
      };
      this.genereateCustomerCommonOtp(params).then((data) => {
        if (!data.data.error) {
          this.opt_show = true;
          this.mobile_show = false;
          this.start();
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    formatTime(time_obj) {
      return time_obj.HH + ":" + time_obj.mm;
    },
    genereateRegisterOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        account_exists: this.customerAccountExist,
        reserved_date: this.reserved_date
          ? moment(this.reserved_date).format("YYYY-MM-DD")
          : "",
        reserved_time: _.isEqual(this.reserved_time, this.initial_time)
          ? ""
          : this.formatTime(this.reserved_time),
        tenant_id: this.tenant_id,
        no_of_people: this.no_of_people,
        area_id: this.area_id,
        remarks: this.remarks,
        first_name: this.first_name,
        last_name: this.last_name,
      };
      this.genereateCustomerOtpForReservation(params).then((data) => {
        if (!data.data.error) {
          this.opt_show = true;
          this.mobile_show = false;
          this.start();
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    genereateLoginOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        reserved_date: this.reserved_date
          ? moment(this.reserved_date).format("YYYY-MM-DD")
          : "",
        reserved_time: _.isEqual(this.reserved_time, this.initial_time)
          ? ""
          : this.formatTime(this.reserved_time),
        tenant_id: this.tenant_id,
        no_of_people: this.no_of_people,
        area_id: this.area_id,
        remarks: this.remarks,
        first_name: this.first_name,
        last_name: this.last_name,
      };
      this.checkCustomerMobileForReservation(params).then((data) => {
        if (!data.data.error) {
          this.opt_show = true;
          this.mobile_show = false;
          this.start();
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    checkInfoValidity() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        email: this.form_params.email,
        first_name: this.form_params.first_name,
        last_name: this.form_params.last_name,
        refresh_required: false,
      };
      this.checkCustomerInfoValidity(params).then((data) => {
        if (!data.data.error) {
        } else {
          this.$refs.step2_form.setErrors(data.data.error);
        }
      });
    },
    handleOnOtpComplete(value) {
      this.form_params.otp = value;
      const params = {
        mobile_no: this.form_params.mobile_no,
        otp: this.form_params.otp,
      };
      this.checkOtpValidity(params).then((data) => {
        if (!data.data.error) {
          this.stop();
          this.vm.isTimerRunning = false;
          clearInterval(this.timer);
          this.vm.timer = null;

          const params = {
            mobile_no: this.form_params.mobile_no,
            refresh_required: false,
            first_name: this.first_name,
            last_name: this.last_name,
            account_exists: this.customerAccountExist,
            reserved_date: this.reserved_date
              ? moment(this.reserved_date).format("YYYY-MM-DD")
              : "",
            reserved_time: _.isEqual(this.reserved_time, this.initial_time)
              ? ""
              : this.formatTime(this.reserved_time),
            tenant_id: this.tenant_id,
            no_of_people: this.no_of_people,
            area_id: this.area_id,
            remarks: this.remarks,
          };
          this.generateCustomerTokenAfterReservation(params).then((data) => {
            if (!data.data.error) {
              this.opt_show = false;
              this.mobile_show = false;
              this.start();
              this.resetReservationForm();
              this.$refs.step1_form.reset();
            } else {
              this.$refs.step1_form.setErrors(data.data.error);
            }
          });
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    handleOnComplete(value) {
      if (this.vm.customerAccountExist == "No") {
        this.form_params.otp = value;
        const params = {
          mobile_no: this.form_params.mobile_no,
          otp: this.form_params.otp,
        };
        this.checkOtpValidity(params).then((data) => {
          if (!data.data.error) {
            this.stop();
            this.vm.isTimerRunning = false;
            clearInterval(this.timer);
            this.vm.timer = null;
            this.vm.$refs.wizard.changeTab(0, 1);

            const params = {
              mobile_no: this.form_params.mobile_no,
              refresh_required: false,
              first_name: this.first_name,
              last_name: this.last_name,
              account_exists: this.customerAccountExist,
              reserved_date: this.reserved_date
                ? moment(this.reserved_date).format("YYYY-MM-DD")
                : "",
              reserved_time: _.isEqual(this.reserved_time, this.initial_time)
                ? ""
                : this.formatTime(this.reserved_time),
              tenant_id: this.tenant_id,
              no_of_people: this.no_of_people,
              area_id: this.area_id,
              remarks: this.remarks,
            };
            this.generateCustomerTokenAfterReservation(params).then((data) => {
              if (!data.data.error) {
                this.opt_show = true;
                this.mobile_show = false;
                this.start();
                this.resetReservationForm();
                this.$refs.step1_form.reset();
              } else {
                this.$refs.step1_form.setErrors(data.data.error);
              }
            });
          } else {
            this.$refs.step1_form.setErrors(data.data.error);
          }
        });
      } else {
        this.form_params.otp = value;
        const params = {
          mobile_no: this.form_params.mobile_no,
          otp: this.form_params.otp,
        };
        this.checkOtpValidity(params).then((data) => {
          if (!data.data.error) {
            this.stop();
            this.vm.isTimerRunning = false;
            clearInterval(this.timer);
            this.vm.timer = null;

            const params = {
              mobile_no: this.form_params.mobile_no,
              refresh_required: false,
              first_name: this.first_name,
              last_name: this.last_name,
              account_exists: this.customerAccountExist,
              reserved_date: this.reserved_date
                ? moment(this.reserved_date).format("YYYY-MM-DD")
                : "",
              reserved_time: _.isEqual(this.reserved_time, this.initial_time)
                ? ""
                : this.formatTime(this.reserved_time),
              tenant_id: this.tenant_id,
              no_of_people: this.no_of_people,
              area_id: this.area_id,
              remarks: this.remarks,
            };
            this.generateCustomerTokenAfterReservation(params).then((data) => {
              if (!data.data.error) {
                this.opt_show = true;
                this.mobile_show = false;
                this.start();
                this.resetReservationForm();
                this.$refs.step1_form.reset();
                this.$refs.reservation_form.reset();
              } else {
                this.$refs.step1_form.setErrors(data.data.error);
              }
            });
          } else {
            this.$refs.step1_form.setErrors(data.data.error);
          }
        });
      }
    },
    resetReservationForm() {
      this.form_params.mobile_no = "";
      this.reserved_date = null;
      this.reserved_time = {
        HH: "",
        mm: "",
      };
      this.tenant_id = "";
      this.no_of_people = "";
      this.area_id = "";
      this.remarks = "";
      this.first_name = "";
      this.last_name = "";
    },
    start() {
      this.isTimerRunning = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            clearInterval(this.timer);
            this.reset();
          }
        }, 1000);
      }
    },
    stop() {
      this.isTimerRunning = false;
      clearInterval(this.timer);
      this.timer = null;
    },
    reset() {
      this.stop();
      this.time = 0;
      this.secondes = 0;
      this.minutes = 0;
      this.isTimerRunning = false;
      this.timer = null;
    },
    setTime(payload) {
      this.time = payload.minutes * 60 + payload.secondes;
    },

    checkValidInput(error) {
      if (error.length) {
        return false;
      }
    },
    accountExists(value) {
      if (value) {
        this.customerAccountExist = "Yes";
      } else {
        this.customerAccountExist = "No";
      }
    },
    redirectToLink(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
    resetForm() {
      this.reserved_date = null;
      this.reserved_time = {
        HH: "",
        mm: "",
      };
      this.no_of_people = "";
      this.area_id = "";
      this.remarks = "";
    },
    reservationCreate() {
      if (this.customer_access_token) {
        const params = {
          reserved_date: this.reserved_date
            ? moment(this.reserved_date).format("YYYY-MM-DD")
            : "",
          reserved_time: _.isEqual(this.reserved_time, this.initial_time)
            ? ""
            : this.formatTime(this.reserved_time),
          tenant_id: this.tenant_id,
          no_of_people: this.no_of_people,
          area_id: this.area_id,
          remarks: this.remarks,
        };
        this.reservationBooking(params).then((data) => {
          if (!data.data.error) {
            this.resetForm();
          } else {
            this.$refs.reservation_form.setErrors(data.data.error);
          }
          this.isLoading = false;
        });
      } else {
      }
    },
    mapRedirect(restaurant) {
      let location = JSON.parse(restaurant.location);
      let route = this.$router.resolve({
        path: "/pages/map?lat=" + location.lat + "&lng=" + location.lng + "",
      });
      window.open(route.href, "_blank");
    },
    setActivebtn(meal_type) {
      this.isLoading = true;
      this.$store.state.activeMealTypeBtn = meal_type;
      const params = {
        meal_type: meal_type,
        tenant_id: this.tenant_id,
      };
      this.filterMenuItems(params).then((data) => {
        if (!data.data.error) {
          this.restaurantMenuCategory = data.data;
        }
        this.isLoading = false;
      });
      this.$store.state.activeMenuCategoryId = "";
    },
    getDynamicColor(meal_type) {
      if (this.$store.state.activeMealTypeBtn == meal_type) {
        return "primary";
      } else {
        return "light";
      }
    },
    getDynamicCategoryBtnColor(categoryId) {
      if (this.$store.state.activeMenuCategoryId == categoryId) {
        return "primary";
      } else {
        return "light";
      }
    },
    setActiveMenuCategoryBtn(categoryId) {
      this.$store.state.activeMenuCategoryId = categoryId;
    },
    getDynamimcCategoryDIVRef(categoryId) {
      return "category_div_" + categoryId;
    },
  },
  updated() {
    let option = {
      el: "#area_div",
      container: "#rest-bg-imge",
      duration: 500,
      easing: "linear",
      offset: -100,
      force: true,
      cancelable: true,
      x: false,
      y: true,
    };
    if (this.$route.query.redirect_to == "reservation") {
      this.$scrollTo("#reservation_div", 500, option);
    }
  },
  mounted() {
    this.vm = this;
    let tenant_id = this.$route.params.tenant_id;
    this.tenant_id = tenant_id;
    const params = {
      tenant_id: tenant_id,
      meal_type: "all",
    };

    this.getRestaurantData(params).then((data) => {
      if (!data.data.error) {
        this.restaurant_name = data.data.restaurant_name;
        this.menuItems = data.data.menu_items;
        this.menuSliders = data.data.image_collection;
        this.restaurantData = data.data;
        this.areaData = data.data.areas;
        this.menuBackgroundImage =
          data.data.reservation_back_image_collection[0].url;
      }
    });

    const area_params = {
      tenant_id: tenant_id,
    };

    this.areaList(area_params).then((data) => {
      if (!data.data.error) {
        this.areaListArray = data.data;
      }
    });
  },
  data() {
    return {
      value: null,
      options: [
        {
          id: "a",
          label: "a",
          children: [
            {
              id: "aa",
              label: "aa",
            },
            {
              id: "ab",
              label: "ab",
            },
          ],
        },
        {
          id: "b",
          label: "b",
        },
        {
          id: "c",
          label: "c",
        },
      ],
      restaurant_name: "",
      menuItems: [],
      menuSliders: [],
      restaurantData: [],
      tenant_id: "",
      restaurantMenuCategory: [],
      isLoading: false,
      fullPage: true,
      reserved_date: null,
      reserved_time: {
        HH: "",
        mm: "",
      },
      customer_name: "",
      mobile_no: "",
      email: "",
      no_of_people: "",
      area_id: "",
      remarks: "",
      areaListArray: [],
      areaData: [],
      customerAccountExist: "Yes",
      otp_mask:"",
      form_params: {
        mobile_no: "",
        first_name: "",
        last_name: "",
        email: "",
        otp: "",
      },
      opt_show: false,
      mobile_show: true,
      vm: "",
      isTimerRunning: false,
      minutes: 2,
      secondes: 0,
      time: 120,
      timer: null,
      account_exists: true,
      first_name: "",
      last_name: "",
      menuBackgroundImage: "",
      disabledReservationDates: {
        to: currentDate,
      },
      carousel_interval: 0,
      center: { lat: 6.9271, lng: 79.8612 },
      initial_time: {
        HH: "",
        mm: "",
      },
    };
  },
  computed: {
    ...mapState("user", ["user_name", "customer_access_token"]),
    ...mapGetters("user", ["loggedIn"]),
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },
  },
};
</script>

<style>
.custom-wizard .wizard-card-footer {
  padding: 0 !important;
}

.custom-wizard .wizard-tab-content {
  padding: 0 !important;
}
.custom-wizard .wizard-header {
  display: none;
}

.display-time {
  background-color: rgba(0, 0, 0, 0.747) !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.container-fluid {
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.c-main {
  padding-top: 0rem !important;
  margin-bottom: -28px !important;
  /* remove padding will display whitel layers in bottom  */
}

.form-control {
  background-color: rgba(0, 0, 0, 0.747) !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

label {
  color: #ffffff !important;
}

.custom-readmore a {
  text-decoration: none;
  background-color: transparent;
  color: #9f6bff !important;
}
</style>

<style scoped>
.min-height-scroll-fixer {
  min-height: 100px !important;
}
.wizard-btn-smaple {
  padding: 18px;
}

.min-height-desc {
  min-height: 65px;
}

.social-color {
  color: #ffffff;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.social-color:hover {
  color: #a443f3;
  cursor: pointer;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: grid !important;
  height: 300px !important;
}

.rating-arrangment {
  float: right;
}

.resturent-main-card {
  background-color: rgba(0, 0, 0, 0.425);
}

.resturent-reserv-card {
  background-color: rgba(0, 0, 0, 0.795);
}

.card-title,
.location-text,
.address-text,
.description-text,
.min-height-desc,
.discount-text,
.title-text {
  color: #ffffff;
}

.category-color {
  background-color: rgba(255, 255, 255, 0.493);
  color: #ffffff;
}

.location-color {
  color: rgb(167, 72, 255);
}

.rest-bg-imge {
  /* background-image: url("/img/bg_images/rest-img.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.rating-arrangment {
  float: right;
}

.min-height-card {
  min-height: 350px;
  border-radius: 1.5rem !important;
  /* border: 0.1rem solid #ffffff; */
  background-color: rgba(0, 0, 0, 0.651);
  padding-bottom: 0.8rem;
  box-shadow: rgb(28 28 28 / 12%) 0px 0.4rem 1.8rem;
}

.Row {
  display: table;
  width: 100%;
  /*Optional*/
  table-layout: fixed;
  /*Optional*/
  border-spacing: 10px;
  /*Optional*/
}

.Column {
  display: table-cell;
}

@media (max-width: 768px) {
  .rating-arrangment {
    float: none;
  }

  .tree-select {
    padding-top: 20px;
  }

  .rating-arrangment {
    float: none;
  }

  .meal-plan {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }

  .carousel {
    height: 200px !important;
  }

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: grid !important;
    height: 200px !important;
  }
}

@media (min-width: 1900px) {
  .carousel {
    height: 500px !important;
  }

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: grid !important;
    height: 500px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .rating-ipad {
    float: right;
  }
}
</style>
