<template>
  <div>
    <CRow>
      <CCol col="12">
        <GmapMap
          :center="center"
          :zoom="12"
          map-style-id="roadmap"
          :options="mapOptions"
          style="width: 100vmin; height: 100vmin"
          ref="mapRef"
          @click="handleMapClick"
        >
          <GmapMarker
            :position="marker.position"
            :clickable="true"
            :draggable="true"
            @drag="handleMarkerDrag"
            @click="panToMarker"
          />
        </GmapMap>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "RestaurantMapView",
  components: {},
  props: ['lat' , 'lng'],
  data() {
    return {
      marker: { position: { lat: parseFloat(this.lat), lng: parseFloat(this.lng) } },
      center: { lat: parseFloat(this.lat), lng: parseFloat(this.lng) },
      mapOptions: {
        disableDefaultUI: true,
      }
    };
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: parseFloat(this.lat),
          lng: parseFloat(this.lng),
        };

        this.panToMarker();
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      //this.$refs.mapRef.setZoom(18);
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    },
  },
};
</script>

<style scoped>
.vue-map-container{
  width:100% !important;
}
</style>
